import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const PoliticaDePrivacidad = ({ s }) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="privacy">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.politicadeprivacidad?.title)} />
					<main className="page lightgray-bg-01">
						{intl.locale === "es" && (
							<>
								<article className="box black-bg-02 title">
									<h1 className="prequel-font white-text align-center">POLITICA DE PRIVACIDAD</h1>
								</article>
								<ul className="breadcrumbs">
									<li className="link">
										<Link to="/" className="ltblue-text-02">
											Home
										</Link>
									</li>
									<li className="active show-lg">
										<p className="lightgray-text-07">Política de privacidad</p>
									</li>
								</ul>
								<article className="box text">
									<p>
										BANSAH & HOHOE S.L., en aplicación de la normativa vigente en materia de protección de
										datos de carácter personal, informa que los datos personales que se recogen a través de
										los formularios del Sitio web: www.shareyourboard.app, se incluyen en los ficheros
										automatizados específicos de usuarios de los servicios de BANSAH & HOHOE S.L.
									</p>
									<p>
										La recogida y tratamiento automatizado de los datos de carácter personal tiene como
										finalidad el mantenimiento de la relación comercial y el desempeño de tareas de
										información, formación, asesoramiento y otras actividades propias de BANSAH & HOHOE S.L.
									</p>

									<p>
										Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único
										objetivo de dar cumplimiento a la finalidad anteriormente expuesta. BANSAH & HOHOE S.L.
										adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad
										de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo
										y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en
										lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos,
										y derogando la antigua LOPD, la nueva Ley Orgánica 3/2018, de 5 diciembre, de Protección
										de Datos y Garantía de los Derechos Digitales (LOPDGDD).
									</p>

									<p>
										El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición,
										rectificación, cancelación, limitación y portabilidad reconocidos en el citado Reglamento
										(UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de email
										a: hello@shareyourboard.app o en la dirección: CL PERE IV NUM.51 P.2 PTA.3 BARCELONA
										08018.
									</p>

									<p>
										El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se
										compromete a mantenerlos actualizados, comunicando los cambios a BANSAH & HOHOE S.L.
									</p>

									<p>Finalidad del tratamiento de los datos personales:</p>

									<div className="wrapper">
										<p>
											<b>¿Con qué finalidad trataremos tus datos personales?</b>
											<br />
											<br />
											En BANSAH & HOHOE S.L., trataremos tus datos personales recabados a través del Sitio
											Web: www.shareyourboard.app, con las siguientes finalidades:
											<br />
											<br />
											<ul>
												<li>
													1- Prestar sus servicios de acuerdo con las necesidades particulares de los
													clientes, con el fin de cumplir los contratos suscritos por la misma.
												</li>
												<li>
													2- Envío de información comercial y boletines sobre nuevos servicios ofrecidos en
													la web y del sector.
												</li>
												<li>
													3- El proceso de archivo, de actualización de los sistemas, de protección y
													custodia de información y bases de datos de la empresa.
												</li>
												<li>4- Ejecutar un contrato suscrito a distancia con el usuario.</li>
												<li>5- Prestar los servicios contratados por el usuario.</li>
												<li>6- Enviar información promocional vía electrónica.</li>
												<li>
													7- Facilitar la información solicitada por el usuario a través del formulario de
													contacto.
												</li>
												<li>8- Gestión de los alquileres y cesiones de las tablas de surf</li>
											</ul>
											Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier
											vía y en cualquier momento, remitiendo un correo electrónico a la dirección
											anteriormente indicada.
											<br />
											<br />
											Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible
											realizar las finalidades expresadas si no se aportan esos datos.
											<br />
											<br />
											<b>¿Por cuánto tiempo se conservan los datos personales recabados?</b>
											<br />
											<br />
											Los datos personales proporcionados se conservarán mientras se mantenga la relación
											comercial o no solicites su supresión y durante el plazo por el cual pudieran derivarse
											responsabilidades legales por los servicios prestados.
											<br />
											<br />
											<h3 className="prequel-font red-text">LEGITIMACION</h3>
											<br />
											El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman
											el mismo:
											<br />
											<br />
											<ul>
												<li>
													1- La solicitud de información y/o la contratación de los servicios de BANSAH &
													HOHOE S.L., cuyos términos y condiciones se pondrán a tu disposición en todo
													caso, de forma previa a una eventual contratación.
												</li>
												<li>
													2- El consentimiento libre, específico, informado e inequívoco, en tanto que te
													informamos poniendo a tu disposición la presente política de privacidad, que tras
													la lectura de la misma, en caso de estar conforme, puedes aceptar mediante una
													declaración o una clara acción afirmativa, como el marcado de una casilla
													dispuesta al efecto.
												</li>
											</ul>
											En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no
											podremos atender tu solicitud, resultando del todo imposible proporcionarte la
											información solicitada o llevar a cabo la contratación de los servicios.
											<br />
											<br />
											<h3 className="prequel-font red-text">DESTINATARIOS</h3>
											<br />
											Los datos no se comunicarán a ningún tercero ajeno a BANSAH & HOHOE S.L., salvo
											obligación legal.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Datos recopilados por usuarios de los servicios
											</h3>
											<br />
											En los casos en que el usuario incluya ficheros con datos de carácter personal en los
											servidores de alojamiento compartido, BANSAH & HOHOE S.L. no se hace responsable del
											incumplimiento por parte del usuario del RGPD.
											<br />
											<br />
											<h3 className="prequel-font red-text">Retencion de datos en conformidad a la LSSI</h3>
											<br />
											BANSAH & HOHOE S.L. informa de que, como prestador de servicio de alojamiento de datos
											y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la
											Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un periodo
											máximo de 12 meses la información imprescindible para identificar el origen de los
											datos alojados y el momento en que se inició la prestación del servicio. La retención
											de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados
											en el marco de una investigación criminal o para la salvaguardia de la seguridad
											pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así
											los requiera. La comunicación de datos a las Fuerzas y Cuerpos del Estado se hará en
											virtud a lo dispuesto en la normativa sobre protección de datos personales.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Derechos propiedad intelectual www.shareyourboard.app
											</h3>
											<br />
											BANSAH & HOHOE S.L. es titular de todos los derechos de autor, propiedad intelectual,
											industrial, "know how" y cuantos otros derechos guardan relación con los contenidos del
											sitio web www.shareyourboard.app y los servicios ofertados en el mismo, así como de los
											programas necesarios para su implementación y la información relacionada.
											<br />
											<br />
											No se permite la reproducción, publicación y/o uso no estrictamente privado de los
											contenidos, totales o parciales, del sitio web www.shareyourboard.app sin el
											consentimiento previo y por escrito.
											<br />
											<br />
											<h3 className="prequel-font red-text">Propiedad intelectual del SOFTWARE</h3>
											<br />
											El usuario debe respetar los programas de terceros puestos a su disposición por BANSAH
											& HOHOE S.L., aún siendo gratuitos y/o de disposición pública.
											<br />
											<br />
											BANSAH & HOHOE S.L. dispone de los derechos de explotación y propiedad intelectual
											necesarios del software.
											<br />
											<br />
											El usuario no adquiere derecho alguno o licencia por el servicio contratado, sobre el
											software necesario para la prestación del servicio, ni tampoco sobre la información
											técnica de seguimiento del servicio, excepción hecha de los derechos y licencias
											necesarios para el cumplimiento de los servicios contratados y únicamente durante la
											duración de los mismos.
											<br />
											<br />
											Para toda actuación que exceda del cumplimiento del contrato, el usuario necesitará
											autorización por escrito por parte de BANSAH & HOHOE S.L., quedando prohibido al
											usuario acceder, modificar, visualizar la configuración, estructura y ficheros de los
											servidores propiedad de BANSAH & HOHOE S.L., asumiendo la responsabilidad civil y penal
											derivada de cualquier incidencia que se pudiera producir en los servidores y sistemas
											de seguridad como consecuencia directa de una actuación negligente o maliciosa por su
											parte.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Propiedad intelectual de los contenidos alojados
											</h3>
											<br />
											Se prohíbe el uso contrario a la legislación sobre propiedad intelectual de los
											servicios prestados por BANSAH & HOHOE S.L. y, en particular de:
											<br />
											<br />
											<ul>
												<li>
													1- La utilización que resulte contraria a las leyes españolas o que infrinja los
													derechos de terceros.
												</li>
												<li>
													2- La publicación o la transmisión de cualquier contenido que, a juicio de BANSAH
													& HOHOE S.L., resulte violento, obsceno, abusivo, ilegal, racial, xenófobo o
													difamatorio.
												</li>
												<li>
													3- Los cracks, números de serie de programas o cualquier otro contenido que
													vulnere derechos de la propiedad intelectual de terceros.
												</li>
												<li>
													4- La recogida y/o utilización de datos personales de otros usuarios sin su
													consentimiento expreso o contraviniendo lo dispuesto en el Reglamento (UE)
													2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
													la protección de las personas físicas en lo que respecta al tratamiento de datos
													personales y a la libre circulación de los mismos.
												</li>
												<li>
													5- La utilización del servidor de correo del dominio y de las direcciones de
													correo electrónico para el envío de correo masivo no deseado.
												</li>
											</ul>
											El usuario tiene toda la responsabilidad sobre el contenido de su web, la información
											transmitida y almacenada, los enlaces de hipertexto, las reivindicaciones de terceros y
											las acciones legales en referencia a propiedad intelectual, derechos de terceros y
											protección de menores.
											<br />
											<br />
											El usuario es responsable respecto a las leyes y reglamentos en vigor y las reglas que
											tienen que ver con el funcionamiento del servicio online, comercio electrónico,
											derechos de autor, mantenimiento del orden público, así como principios universales de
											uso de Internet.
											<br />
											<br />
											El usuario indemnizará a BANSAH & HOHOE S.L. por los gastos que generara la imputación
											de BANSAH & HOHOE S.L. en alguna causa cuya responsabilidad fuera atribuible al
											usuario, incluidos honorarios y gastos de defensa jurídica, incluso en el caso de una
											decisión judicial no definitiva.
											<br />
											<br />
											<h3 className="prequel-font red-text">ProtecciOn de la informaciOn alojada</h3>
											<br />
											BANSAH & HOHOE S.L. realiza copias de seguridad de los contenidos alojados en sus
											servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de
											los datos por parte de los usuarios. De igual manera, no garantiza la reposición total
											de los datos borrados por los usuarios, ya que los citados datos podrían haber sido
											suprimidos y/o modificados durante el periodo del tiempo transcurrido desde la última
											copia de seguridad.
											<br />
											<br />
											Los servicios ofertados, excepto los servicios específicos de backup, no incluyen la
											reposición de los contenidos conservados en las copias de seguridad realizadas por
											BANSAH & HOHOE S.L., cuando esta pérdida sea imputable al usuario; en este caso, se
											determinará una tarifa acorde a la complejidad y volumen de la recuperación, siempre
											previa aceptación del usuario.
											<br />
											<br />
											La reposición de datos borrados sólo está incluida en el precio del servicio cuando la
											pérdida del contenido sea debida a causas atribuibles a BANSAH & HOHOE S.L.
											<br />
											<br />
											<h3 className="prequel-font red-text">Comunicaciones comerciales</h3>
											<br />
											En aplicación de la LSSI. BANSAH & HOHOE S.L. no enviará comunicaciones publicitarias o
											promocionales por correo electrónico u otro medio de comunicación electrónica
											equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por
											los destinatarios de las mismas.
											<br />
											<br />
											En el caso de usuarios con los que exista una relación contractual previa, Living
											Crowdland SL sí está autorizado al envío de comunicaciones comerciales referentes a
											productos o servicios de BANSAH & HOHOE S.L. que sean similares a los que inicialmente
											fueron objeto de contratación con el cliente.
											<br />
											<br />
											En todo caso, el usuario, tras acreditar su identidad, podrá solicitar que no se le
											haga llegar más información comercial a través de los canales de Atención al Cliente.
										</p>
									</div>
								</article>
							</>
						)}
						{intl.locale !== "es" && (
							<>
								<article className="box black-bg-02 title">
									<h1 className="prequel-font white-text align-center">POLITICA DE PRIVACIDAD</h1>
								</article>
								<article className="box text">
									<p>
										BANSAH & HOHOE S.L., en aplicación de la normativa vigente en materia de protección de
										datos de carácter personal, informa que los datos personales que se recogen a través de
										los formularios del Sitio web: www.shareyourboard.app, se incluyen en los ficheros
										automatizados específicos de usuarios de los servicios de BANSAH & HOHOE S.L.
									</p>
									<p>
										La recogida y tratamiento automatizado de los datos de carácter personal tiene como
										finalidad el mantenimiento de la relación comercial y el desempeño de tareas de
										información, formación, asesoramiento y otras actividades propias de BANSAH & HOHOE S.L.
									</p>

									<p>
										Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único
										objetivo de dar cumplimiento a la finalidad anteriormente expuesta. BANSAH & HOHOE S.L.
										adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad
										de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo
										y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en
										lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos,
										y derogando la antigua LOPD, la nueva Ley Orgánica 3/2018, de 5 diciembre, de Protección
										de Datos y Garantía de los Derechos Digitales (LOPDGDD).
									</p>

									<p>
										El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición,
										rectificación, cancelación, limitación y portabilidad reconocidos en el citado Reglamento
										(UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de email
										a: hello@shareyourboard.app o en la dirección: CL PERE IV NUM.51 P.2 PTA.3 BARCELONA
										08018.
									</p>

									<p>
										El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se
										compromete a mantenerlos actualizados, comunicando los cambios a BANSAH & HOHOE S.L.
									</p>

									<p>Finalidad del tratamiento de los datos personales:</p>

									<div className="wrapper">
										<p>
											<b>¿Con qué finalidad trataremos tus datos personales?</b>
											<br />
											<br />
											En BANSAH & HOHOE S.L., trataremos tus datos personales recabados a través del Sitio
											Web: www.shareyourboard.app, con las siguientes finalidades:
											<br />
											<br />
											<ul>
												<li>
													1- Prestar sus servicios de acuerdo con las necesidades particulares de los
													clientes, con el fin de cumplir los contratos suscritos por la misma.
												</li>
												<li>
													2- Envío de información comercial y boletines sobre nuevos servicios ofrecidos en
													la web y del sector.
												</li>
												<li>
													3- El proceso de archivo, de actualización de los sistemas, de protección y
													custodia de información y bases de datos de la empresa.
												</li>
												<li>4- Ejecutar un contrato suscrito a distancia con el usuario.</li>
												<li>5- Prestar los servicios contratados por el usuario.</li>
												<li>6- Enviar información promocional vía electrónica.</li>
												<li>
													7- Facilitar la información solicitada por el usuario a través del formulario de
													contacto.
												</li>
												<li>8- Gestión de los alquileres y cesiones de las tablas de surf</li>
											</ul>
											Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier
											vía y en cualquier momento, remitiendo un correo electrónico a la dirección
											anteriormente indicada.
											<br />
											<br />
											Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible
											realizar las finalidades expresadas si no se aportan esos datos.
											<br />
											<br />
											<b>¿Por cuánto tiempo se conservan los datos personales recabados?</b>
											<br />
											<br />
											Los datos personales proporcionados se conservarán mientras se mantenga la relación
											comercial o no solicites su supresión y durante el plazo por el cual pudieran derivarse
											responsabilidades legales por los servicios prestados.
											<br />
											<br />
											<h3 className="prequel-font red-text">LEGITIMACION</h3>
											<br />
											El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman
											el mismo:
											<br />
											<br />
											<ul>
												<li>
													1- La solicitud de información y/o la contratación de los servicios de BANSAH &
													HOHOE S.L., cuyos términos y condiciones se pondrán a tu disposición en todo
													caso, de forma previa a una eventual contratación.
												</li>
												<li>
													2- El consentimiento libre, específico, informado e inequívoco, en tanto que te
													informamos poniendo a tu disposición la presente política de privacidad, que tras
													la lectura de la misma, en caso de estar conforme, puedes aceptar mediante una
													declaración o una clara acción afirmativa, como el marcado de una casilla
													dispuesta al efecto.
												</li>
											</ul>
											En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no
											podremos atender tu solicitud, resultando del todo imposible proporcionarte la
											información solicitada o llevar a cabo la contratación de los servicios.
											<br />
											<br />
											<h3 className="prequel-font red-text">DESTINATARIOS</h3>
											<br />
											Los datos no se comunicarán a ningún tercero ajeno a BANSAH & HOHOE S.L., salvo
											obligación legal.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Datos recopilados por usuarios de los servicios
											</h3>
											<br />
											En los casos en que el usuario incluya ficheros con datos de carácter personal en los
											servidores de alojamiento compartido, BANSAH & HOHOE S.L. no se hace responsable del
											incumplimiento por parte del usuario del RGPD.
											<br />
											<br />
											<h3 className="prequel-font red-text">Retencion de datos en conformidad a la LSSI</h3>
											<br />
											BANSAH & HOHOE S.L. informa de que, como prestador de servicio de alojamiento de datos
											y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la
											Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un periodo
											máximo de 12 meses la información imprescindible para identificar el origen de los
											datos alojados y el momento en que se inició la prestación del servicio. La retención
											de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados
											en el marco de una investigación criminal o para la salvaguardia de la seguridad
											pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así
											los requiera. La comunicación de datos a las Fuerzas y Cuerpos del Estado se hará en
											virtud a lo dispuesto en la normativa sobre protección de datos personales.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Derechos propiedad intelectual www.shareyourboard.app
											</h3>
											<br />
											BANSAH & HOHOE S.L. es titular de todos los derechos de autor, propiedad intelectual,
											industrial, "know how" y cuantos otros derechos guardan relación con los contenidos del
											sitio web www.shareyourboard.app y los servicios ofertados en el mismo, así como de los
											programas necesarios para su implementación y la información relacionada.
											<br />
											<br />
											No se permite la reproducción, publicación y/o uso no estrictamente privado de los
											contenidos, totales o parciales, del sitio web www.shareyourboard.app sin el
											consentimiento previo y por escrito.
											<br />
											<br />
											<h3 className="prequel-font red-text">Propiedad intelectual del SOFTWARE</h3>
											<br />
											El usuario debe respetar los programas de terceros puestos a su disposición por BANSAH
											& HOHOE S.L., aún siendo gratuitos y/o de disposición pública.
											<br />
											<br />
											BANSAH & HOHOE S.L. dispone de los derechos de explotación y propiedad intelectual
											necesarios del software.
											<br />
											<br />
											El usuario no adquiere derecho alguno o licencia por el servicio contratado, sobre el
											software necesario para la prestación del servicio, ni tampoco sobre la información
											técnica de seguimiento del servicio, excepción hecha de los derechos y licencias
											necesarios para el cumplimiento de los servicios contratados y únicamente durante la
											duración de los mismos.
											<br />
											<br />
											Para toda actuación que exceda del cumplimiento del contrato, el usuario necesitará
											autorización por escrito por parte de BANSAH & HOHOE S.L., quedando prohibido al
											usuario acceder, modificar, visualizar la configuración, estructura y ficheros de los
											servidores propiedad de BANSAH & HOHOE S.L., asumiendo la responsabilidad civil y penal
											derivada de cualquier incidencia que se pudiera producir en los servidores y sistemas
											de seguridad como consecuencia directa de una actuación negligente o maliciosa por su
											parte.
											<br />
											<br />
											<h3 className="prequel-font red-text">
												Propiedad intelectual de los contenidos alojados
											</h3>
											<br />
											Se prohíbe el uso contrario a la legislación sobre propiedad intelectual de los
											servicios prestados por BANSAH & HOHOE S.L. y, en particular de:
											<br />
											<br />
											<ul>
												<li>
													1- La utilización que resulte contraria a las leyes españolas o que infrinja los
													derechos de terceros.
												</li>
												<li>
													2- La publicación o la transmisión de cualquier contenido que, a juicio de BANSAH
													& HOHOE S.L., resulte violento, obsceno, abusivo, ilegal, racial, xenófobo o
													difamatorio.
												</li>
												<li>
													3- Los cracks, números de serie de programas o cualquier otro contenido que
													vulnere derechos de la propiedad intelectual de terceros.
												</li>
												<li>
													4- La recogida y/o utilización de datos personales de otros usuarios sin su
													consentimiento expreso o contraviniendo lo dispuesto en el Reglamento (UE)
													2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
													la protección de las personas físicas en lo que respecta al tratamiento de datos
													personales y a la libre circulación de los mismos.
												</li>
												<li>
													5- La utilización del servidor de correo del dominio y de las direcciones de
													correo electrónico para el envío de correo masivo no deseado.
												</li>
											</ul>
											El usuario tiene toda la responsabilidad sobre el contenido de su web, la información
											transmitida y almacenada, los enlaces de hipertexto, las reivindicaciones de terceros y
											las acciones legales en referencia a propiedad intelectual, derechos de terceros y
											protección de menores.
											<br />
											<br />
											El usuario es responsable respecto a las leyes y reglamentos en vigor y las reglas que
											tienen que ver con el funcionamiento del servicio online, comercio electrónico,
											derechos de autor, mantenimiento del orden público, así como principios universales de
											uso de Internet.
											<br />
											<br />
											El usuario indemnizará a BANSAH & HOHOE S.L. por los gastos que generara la imputación
											de BANSAH & HOHOE S.L. en alguna causa cuya responsabilidad fuera atribuible al
											usuario, incluidos honorarios y gastos de defensa jurídica, incluso en el caso de una
											decisión judicial no definitiva.
											<br />
											<br />
											<h3 className="prequel-font red-text">ProtecciOn de la informaciOn alojada</h3>
											<br />
											BANSAH & HOHOE S.L. realiza copias de seguridad de los contenidos alojados en sus
											servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de
											los datos por parte de los usuarios. De igual manera, no garantiza la reposición total
											de los datos borrados por los usuarios, ya que los citados datos podrían haber sido
											suprimidos y/o modificados durante el periodo del tiempo transcurrido desde la última
											copia de seguridad.
											<br />
											<br />
											Los servicios ofertados, excepto los servicios específicos de backup, no incluyen la
											reposición de los contenidos conservados en las copias de seguridad realizadas por
											BANSAH & HOHOE S.L., cuando esta pérdida sea imputable al usuario; en este caso, se
											determinará una tarifa acorde a la complejidad y volumen de la recuperación, siempre
											previa aceptación del usuario.
											<br />
											<br />
											La reposición de datos borrados sólo está incluida en el precio del servicio cuando la
											pérdida del contenido sea debida a causas atribuibles a BANSAH & HOHOE S.L.
											<br />
											<br />
											<h3 className="prequel-font red-text">Comunicaciones comerciales</h3>
											<br />
											En aplicación de la LSSI. BANSAH & HOHOE S.L. no enviará comunicaciones publicitarias o
											promocionales por correo electrónico u otro medio de comunicación electrónica
											equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por
											los destinatarios de las mismas.
											<br />
											<br />
											En el caso de usuarios con los que exista una relación contractual previa, Living
											Crowdland SL sí está autorizado al envío de comunicaciones comerciales referentes a
											productos o servicios de BANSAH & HOHOE S.L. que sean similares a los que inicialmente
											fueron objeto de contratación con el cliente.
											<br />
											<br />
											En todo caso, el usuario, tras acreditar su identidad, podrá solicitar que no se le
											haga llegar más información comercial a través de los canales de Atención al Cliente.
										</p>
									</div>
								</article>
							</>
						)}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(PoliticaDePrivacidad);
